import { render, staticRenderFns } from "./ModuleSend.vue?vue&type=template&id=647f8247&scoped=true"
import script from "./ModuleSend.vue?vue&type=script&lang=js"
export * from "./ModuleSend.vue?vue&type=script&lang=js"
import style0 from "./ModuleSend.vue?vue&type=style&index=0&id=647f8247&prod&lang=scss&scoped=true"
import style1 from "./ModuleSend.vue?vue&type=style&index=1&id=647f8247&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647f8247",
  null
  
)

export default component.exports